const quotes = [
    { text: "The only way to do great work is to love what you do.", author: "Steve Jobs" },
    { text: "Believe in yourself and all that you are. Know that there is something inside you that is greater than any obstacle.", author: "Christian D. Larson" },
    { text: "Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful.", author: "Albert Schweitzer" },
    { text: "The greatest glory in living lies not in never falling, but in rising every time we fall.", author: "Nelson Mandela" },
    { text: "Positive anything is better than negative nothing.", author: "Elbert Hubbard" },
    { text: "Hardships often prepare ordinary people for an extraordinary destiny.", author: "C.S. Lewis" },
    { text: "You can't build a reputation on what you are going to do.", author: "Henry Ford" },
    { text: "Your positive action combined with positive thinking results in success.", author: "Shiv Khera" },
    { text: "The best way to predict your future is to create it.", author: "Abraham Lincoln" },
    { text: "The only limit to our realization of tomorrow will be our doubts of today.", author: "Franklin D. Roosevelt" },
    { text: "Work hard in silence, let success make the noise.", author: "Unknown" },
    { text: "Success is not final, failure is not fatal: It is the courage to continue that counts.", author: "Winston Churchill" },
    { text: "The best time to plant a tree was 20 years ago. The second best time is now.", author: "Chinese Proverb" },
    { text: "Act as if what you do makes a difference. It does.", author: "William James" },
    { text: "Believe you can and you're halfway there.", author: "Theodore Roosevelt" },
    { text: "You miss 100% of the shots you don't take.", author: "Wayne Gretzky" },
    { text: "You are the average of the five people you spend the most time with.", author: "Jim Rohn" },
    { text: 'Dios sabe como hace las vainas.', author: 'Unknown' },
    { text: "Abran trocha que voy pa' lante.", author: 'Unknown' },
    { text: 'Camarón que se duerme se lo lleva la corriente.', author: 'Unknown' },
    { text: 'Cógela suave que voy en bajada.', author: 'Unknown' },
    { text: 'Cuando tú ibas, ya yo venía.', author: 'Unknown' },
    { text: 'El muerto se hace el pesado cuando tiene quien lo cargue.', author: 'Unknown' },
    { text: 'El que no llora no mama.', author: 'Unknown' },
    { text: 'El que no lo conozca que lo compre.', author: 'Unknown' },
    { text: 'El que pega primero pega dos veces.', author: 'Unknown' },
    { text: 'Hasta donde llovió, hubo barro.', author: 'Unknown' },
    { text: 'La vejez no viene sola.', author: 'Unknown' },
    { text: 'Lo que es del perro no se lo come el gato.', author: 'Unknown' },
    { text: 'Más barranquillero que el arroz de lisa.', author: 'Unknown' },
    { text: 'Más contento que pealo chiquito con juguete nuevo.', author: 'Unknown' },
    { text: 'Más vale llegar a ser, que haber nacido siendo.', author: 'Unknown' },
    { text: 'Me extraña araña que siento mosca no me conozcas.', author: 'Unknown' },
    { text: 'Sin miedo al éxito.', author: 'Unknown' },
    { text: "Va pa' esa, de cabeza y sin casco.", author: 'Unknown' },
    { text: "Ojalá el mar fuera mío, pa' dártelo con to' y pescao.", author: 'Diomedes Díaz' },
    { text: 'Al que le van a dar le guardan y si está frío, se lo calientan.', author: 'Diomedes Díaz' },
    { text: 'Victorious warriors win first and then go to war, while defeated warriors go to war first and then seek to win.', author: 'Sun Tzu' },
    { text: 'He who knows when he can fight and when he cannot, will be victorious.', author: 'Sun Tzu' },
    { text: "Wer anderen eine Freude machen will, der muss sich selbst Freude machen.", author: "Johann Wolfgang von Goethe" },
    { text: "Eine Idee kann nur von jemandem entwickelt werden, der sie hat.", author: "Albert Einstein" },
    { text: "Wer nicht wagt, der nicht gewinnt.", author: "Friedrich der Große" },
    { text: "Wer nicht den Mut hat, seine Meinung zu sagen, der hat auch den Mut nicht, seine Meinung zu ändern.", author: "Konrad Adenauer" },
    { text: "Ein kluger Mensch macht nicht alle Fehler selbst. Er gibt auch anderen eine Chance.", author: "Winston Churchill" },
    { text: "Ein Optimist sieht in jeder Krise eine Chance.", author: "John F. Kennedy" },
    { text: "Wer nicht weiß, wohin er will, kommt nicht an.", author: "Theodor Fontane" },
    { text: "Ein erfolgreiches Leben kann nur jemand führen, der weiß, was er will.", author: "Napoleon Bonaparte" },
    { text: "Wer seine Träume nicht lebt, lebt sein Leben nicht vollständig aus.", author: "Martin Luther King Jr." }
]

export default quotes;